import React, { Component } from 'react'
import ContentLoader from 'react-content-loader'
import { connect } from 'react-redux'
import MobileBackButton from '../../../Elements/MobileBackButton'
import bike from '../../../../assets/images/003.png'
import sub from "../../../../assets/images/001.png"
import coin from '../../../../assets/images/coins.png'
import ProgressiveImage from 'react-progressive-image'
import { getSinglePlan, subscribeNow } from '../../../../redux/mobile/subscription/action'
import Truncate from 'react-truncate'
import { PLACE_HOLDER_IMAGE, PLAN_PAYTM_PAYMENT_URL, PLAN_PROCESS_RAZORPAY_URL, PLAN_PROCESS_STATUS_UPDATE_RAZORPAY_URL } from '../../../../api'
import axios from 'axios'
import { getGateways } from '../../../../redux/mobile/checkOut/action'
import { BottomSheet } from "react-spring-bottom-sheet";


export class SingleSubscriptionPlan extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id,
        plan: [],
        succesRazorData:[],
          gateway_name: "",
        payment_gateway_selected:''
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { user } = this.props;
        this.props.getSinglePlan(user.data.auth_token, this.props.match.params.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, plan: response.payload.plan })
            }
        })

        this.props.getGateways(user.data.auth_token).then((response) => {
            if (response && response.payload) {
                this.setState({
                    //    max_distance: response.payload.distance,
                    gateway_name: response.payload.data,
                });
            }
        });

		const script = document.createElement('script');
		script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        document.body.appendChild(script);

    }

    sendOrderCompleteResponse = (id, payment_id, order_id, signature) => {
		axios.post(PLAN_PROCESS_STATUS_UPDATE_RAZORPAY_URL, {
			id: id,
			token: this.props.user.data.auth_token,
			payment_id,
			order_id,
			signature
		}).then((response) => {
            this.setState({succesRazorData: response?.data})

            // if (response?.data?.success) {
            	this.props.history.push("/subscription-success/" + response?.data?.data?.id);

            // }
        });
	};
    closePayment = () => {
        this.setState({ payment_sheet: false });
    };
    componentWillReceiveProps(nextProps) {
        //// console.log('====================================');
        //// console.log(nextProps);
        //// console.log('====================================');
        // if (nextProps.subscibedPlan?.success && nextProps.subscibedPlan.data.payment_mode == 'ONLINE' && nextProps.subscibedPlan.data.payment_status == "PENDING") {
        //     this.launchRazor(nextProps.subscibedPlan.data, this.props.history, this.sendOrderCompleteResponse, this.successRazor);
        // }
    }

    launchRazor = (order, history, runFunction, successRazor) => {
		//// console.log(parseFloat(order.price).toFixed(2));
		axios.post(PLAN_PROCESS_RAZORPAY_URL, {
			amount: parseFloat(order.price).toFixed(2),
			id: order.id,
			token: this.props.user.data.auth_token
		})
			.then((res) => {

				if (res.data.razorpay_success) {


						const options = {
							key: 'rzp_live_KvJRO6e2wr8Yb9',
							amount: parseFloat(order.price).toFixed(2) * 100,
							name: 'Grosav',
                        webview_intent: true,
							currency: 'INR',
							order_id: res.data.response.id,
							payment_capture: 1,
							method: {
								netbanking: true,
								card: true,
								wallet: false,
								upi: true
							},

							handler(response) {
								//// console.log("Final Response", response);
								runFunction(order.id, response.razorpay_payment_id, response.razorpay_order_id, response.razorpay_signature);

								successRazor();
							},
							modal: {
								ondismiss: function () {
									//// console.log("closed");
									runFunction(order.id, null, null, null);
								},
								confirm_close: true
							},
							prefill: {
								name: this.props.user.data.name,
								contact: this.props.user.data.phone,
								email: this.props.user.data.email ? this.props.user.data.email : '',
							},

						};
						const rzp1 = new window.Razorpay(options);
						rzp1.open();


				}
			});
	}

      launchPaytm = (order) => {
            console.log(`${PLAN_PAYTM_PAYMENT_URL}/${order.id}`);
    
            window.location = `${PLAN_PAYTM_PAYMENT_URL}/${order.id}`;
        };
    // successRazor = () => {
	// 	// this.setState({ order_succes: true, order_confirm: false });
	// 	setTimeout(() => {
	// 		// this.context.router.history.push("/my-orders");
	// 	}, 5000);
	// }
   
    __subscribe = (e , gateway) => {
        e.preventDefault();
        // this.setState({ loading: true })
        this.setState({ payment_sheet: false })

        const { user } = this.props;
        this.props.subscribeNow(user.data.auth_token, this.state.id ,gateway.name).then((response) => {
            console.log(response,"responserrr")
            if (response && response.payload && response.payload.success) {
                console.log(response.payload?.data.payment_gateway_selected,"asdfafd111")
                switch (response.payload?.data.payment_gateway_selected) {
                    case "Razorpay":

                        this.launchRazor(
                            response.payload?.data,
                            this.props.history,
                            this.sendOrderCompleteResponse,
                            this.successRazor
                        );
                        break;

                    case "Paytm":

                        this.launchPaytm(
                            response.payload?.data,
                            this.props.history
                        );
                        break;

                    default:
                        console.warn(
                            "Unknown payment gateway selected:",
                            response.data.payment_gateway_selected
                        );
                }
            } else {
                 console.log("API ERROR", response);
            }
        });
    }
    render() {
        const { plan } = this.state;
        const { user } = this.props;

        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                speed={1}
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="12" y="26" rx="8" ry="8" width="22" height="22" />
                                <rect x="12" y="85" rx="10" ry="10" width="200" height="30" />
                                <rect x="12" y="127" rx="10" ry="10" width="200" height="10" />
                                <rect x="18" y="178" rx="14" ry="10" width="249" height="159" />
                                <rect x="12" y="500" rx="10" ry="18" width="250" height="30" />
                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {plan ? (
                                <React.Fragment>

                                    <div className="d-flex flex-column px-3">
                                        <div className="mobile-ntfcn-head py-4">
                                            <div className="pt-3 px-1">
                                                <MobileBackButton
                                                    history={this.props.history}
                                                />
                                            </div>
                                            <div className="text-center w-100 mt-3">
                                                <ProgressiveImage
                                                    delay={20} src={sub}
                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                >
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            style={{ height: "45vw", borderRadius: "10px", objectFit: "cover", backgroundRepeat: "round", }}
                                                            className=""
                                                            alt="grosav"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </div>
                                            <div className="text-center">
                                                <span className="">{plan.plan_name}</span>
                                            </div>
                                            <div className="text-center">
                                                <span style={{ fontSize: '0.9em', color: '#fea24b' }}>Rs {Math.round(plan.price)}</span>  <span style={{ fontSize: '0.7em', color: '#fea24b' }}>({plan.period} Days)</span>
                                            </div>
                                            <div className="text-center " style={{ fontSize: '0.7em', color: '#fea24b' }}>
                                                <Truncate lines={10} className='shadow-xs'>
                                                    {plan.plan_description}
                                                </Truncate>
                                            </div>
                                        </div>
                                    </div>
                                    {user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0 && user?.data?.activePlan?.subscription_id == plan?.id ? (

                                        <div className="position-fixed px-3 py-2 pb-3 w-100" style={{ zIndex: "2", bottom: "0px", }}>
                                            <button className="btn w-100 py-3 fs-4" style={{ color: "#FEFEFF", fontWeight: "700",  background: '#FEA24B', borderRadius: '15px' }}>
                                                Active Subscription
                                            </button>
                                        </div>
                                    ) : (
                                        <>
                                            {user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0 ? (
                                                <div className="position-fixed px-3 py-2 pb-3 w-100" style={{ zIndex: "2", bottom: "0px", }}>
                                                    <button className="btn w-100 py-3" style={{ color: "#FEFEFF", fontWeight: "700", fontSize: "14px", background: '#FEA24B', borderRadius: '15px' }}>
                                                       Already Subscribed To <span className='fw-bold fs-6'>"{user?.data?.activePlan?.subscription?.plan_name}"</span> Plan
                                                    </button>
                                                </div>
                                            ) : (
<div>

<div className="position-fixed px-3 py-2 pb-3 w-100" style={{ zIndex: "2", bottom: "0px", }}>
                                                    <button className="btn w-100 py-3" style={{ color: "#FEFEFF", fontWeight: "700",
                                                         fontSize: "14px", background: '#FEA24B', borderRadius: '15px' }}
                                                         
                                                        //  onClick={(e) => this.__subscribe(e)}
                                                        onClick={(e) => this.setState({ payment_sheet: true })}
                                                         
                                                         >
                                                        Subscribe now
                                                    </button>


                                                </div>

                                                <BottomSheet
                            open={this.state.payment_sheet}
                            onDismiss={() => this.closePayment()}
                        >
                            <div className="d-flex flex-column justify-content-center px-3 pb-3">
                               

                                {this.state.gateway_name &&
                                this.state.gateway_name.length > 0 ? (
                                    this.state.gateway_name
                                        .filter(
                                            (gateway) => gateway.is_active === 1
                                        ) // Only show active gateways
                                        .map((gateway, index) => (
                                            <div
                                                key={index}
                                                className="mobile-card2 py-3 w-100 mt-3 d-flex flex-row justify-content-center align-items-center"
                                                // onClick={() => this.setOnline(gateway)}
                                                onClick={(e) => this.__subscribe(e, gateway)}
                                                style={{
                                                    border: "1px solid #fea24b",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <img
                                                    src={gateway.image}
                                                    className="cod"
                                                    alt={gateway.name}
                                                    style={{
                                                        height: "20px",
                                                        width: "20px",
                                                    }}
                                                />
                                                <span
                                                    style={{
                                                        fontWeight: "800",
                                                        marginLeft: "10px",
                                                    }}
                                                >
                                                    {gateway.name}
                                                </span>
                                            </div>
                                        ))
                                ) : (
                                    <p className="text-center">
                                        No payment gateways available
                                    </p>
                                )}
                            </div>
                        </BottomSheet>

</div>
                                                
                                            )}
                                        </>
                                    )}
                                    <div className="position-fixed  w-100" style={{ zIndex: "-1", bottom: '0px', backgroundColor: "#fff", backgroundImage: `url(${bike})`, backgroundRepeat: 'round', height: '100vw' }}>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <div className="d-flex flex-column">
                                    <div className="mobile-ntfcn-head py-4">
                                        <div className="pt-3 px-1">
                                            <MobileBackButton
                                                history={this.props.history}
                                            />
                                        </div>
                                        <div className="pt-5 ps-4 text-center">

                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <img
                                            src={coin}
                                            style={{
                                                width: "90%",
                                                borderRadius: "10px",
                                                objectFit: "cover",
                                                backgroundRepeat:
                                                    "round",
                                            }}
                                            className=""
                                            alt="grosav"
                                        />

                                    </div>
                                    <div className="text-center" style={{ fontSize: '1.2em', color: '#fea24b' }}>
                                        No Plans Found
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    subscibedPlan: state.subscription.subscibedPlan
})
export default connect(mapStateToProps, {
    getGateways,
    getSinglePlan, subscribeNow,
})(SingleSubscriptionPlan)
