import React, { Component } from "react";
import { connect } from "react-redux";
import { BsDot, BsSearch } from "react-icons/bs";
import { FaAngleRight, FaRegCheckSquare } from "react-icons/fa";
import { AiOutlineMinus, TiMinus, TiPlus } from "react-icons/ti";
import { HiOutlineLocationMarker } from "react-icons/hi";
import youngWoman from "../../../assets/images/youngWoman.png";
import Ellipse from "../../../assets/images/Ellipse.png";
import Pattern from "../../../assets/images/Pattern.png";
import ContentLoader from "react-content-loader";
import {
    getAllCategory,
    getAllAnyStoreCourierCategory,
} from "../../../redux/mobile/item/action";
import { BottomSheet } from "react-spring-bottom-sheet";
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import { Link } from "react-router-dom";

import { TbEdit } from "react-icons/tb";
import Shake from "react-reveal/Shake";
import Flip from "react-reveal/Fade";
import map from "../../../assets/images/map.png";
import ProgressiveImage from "react-progressive-image";
import {
    ANYSTORE_PROCESS_STATUS_UPDATE_RAZORPAY_URL,
    ANY_STORE_PAYTM_PAYMENT_URL,
    IMAGE_BASE_URL,
    PLACE_HOLDER_IMAGE,
    PAYTM_PAYMENT_ANYSTORE_URL,
    RAZORPAY_ANYSTORE_URL,
} from "../../../api";
import Footer from "../Footer/index";
import Truncate from "react-truncate";
import { MdStore } from "react-icons/md";
import Axios from "axios";
import { GET_ADDRESS_CITY_ANYSTORE } from "../../../api/index";
import axios from "axios";
import ReactPlayer from "react-player";
import { getGateways } from "../../../redux/mobile/checkOut/action";
import { checkDeliveryAddress, checkStoreAddress, placeAnyStoreOrder } from "../../../redux/mobile/store/action";

// const category = [
//     "Documents",
//     "Books",
//     "Stationary",
//     "Grocery",
//     "Household Items",
// ];
export class index extends Component {
    state = {
        count: 0,
        loading: false,
        selectedCategory: null,
        itemmenu: false,
        items: [],
        noText: false,
        address: [],
        storeAddress: [],
        deliveryAddress: [],
        noStoreAddress: false,
        delivery_charge: 0,
        any_store_fee: 0,
        message_store: null,
        message_delivery: null,
        city_id: null,
        needItems: false,
        anyStoreImage: "",
        category: [],
        place_order_alert: false,
        pause_anystore: 0,
        payment_sheet:false,
        gateway_name:''
    };
    openItemMenu = () => {
        this.setState({ itemmenu: true });
        // ////// console.log(this.state.open)
    };
    _closeItemMenu = () => {
        this.setState({ itemmenu: false });
    };
    changeItem = (e, index) => {
        this.setState({ noText: false, needItems: false });
        let value = e.target.value;
        let data = this.state.items[index];
        data.item = value;
        if (data.quantity == 0) {
            data.quantity = 1;
        }
        if (!data.item) {
            this.state.items.splice(index, 1);
        }
        localStorage.setItem("anyStoreItems", JSON.stringify(this.state.items));
        this.setState({ ...this.state });
    };
    handleIncrement = (index) => {
        this.setState({ needItems: false });
        let data = this.state.items[index];
        data.quantity += 1;
        this.setState({ ...this.state });
        localStorage.setItem("anyStoreItems", JSON.stringify(this.state.items));
        // ////// console.log(data, 'add')
    };
    handleDecrement = (index) => {
        // ////// console.log(index)
        this.setState({ needItems: false });
        let data = this.state.items[index];
        if (data.quantity == 1) {
            this.state.items.splice(index, 1);
        } else {
            data.quantity -= 1;
        }
        this.setState({ items: this.state.items });
        localStorage.setItem("anyStoreItems", JSON.stringify(this.state.items));
    };
    checkLocationPause() {
        const userAddress = JSON.parse(localStorage.getItem("userSetAddress"));
        const userLat = userAddress.latitude;
        const userLng = userAddress.longitude;

        Axios.post(GET_ADDRESS_CITY_ANYSTORE, {
            lat: userLat,
            lng: userLng,
        }).then((response) => {
            // ////// console.log(response);
            this.setState({ pause_anystore: response?.data?.pause_anystore });
        });
    }
    closePayment = () => {
        this.setState({ payment_sheet: false });
    };
    handlePaymentSelection = (gatewayName) => {
        this.setState(
            {
                payment_gateway_selected: gatewayName,
                payment_sheet: false,
            },
            () => {
                this.placeStoreOrder(); // Call function after state update
            }
        );
    };
    componentDidMount() {

        const { user } = this.props;
         this.props.getGateways(user.data.auth_token).then((response) => {
                    if (response && response.payload) {
                        this.setState({
                            //    max_distance: response.payload.distance,
                            gateway_name: response.payload.data,
                        });
                    }
                });
        const script = document.createElement("script");
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        document.body.appendChild(script);

        this.checkLocationPause();

        this.setState({
            address: JSON.parse(localStorage.getItem("userSetAddress")),
        });
        // this.setState({ loading: true });
        this.props
            .getAllCategory(
                JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
                JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude
            )
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        anyStoreImage: response.payload.settings,
                        stores: response.payload.stores,
                    });
                } else {
                    ////// console.log("API error");
                }
            });

        this.props.getAllAnyStoreCourierCategory().then((response) => {
            if (response && response.payload && response.payload.success) {
                if (response.payload.anystore?.length > 0) {
                    let cats = response.payload.anystore?.map(
                        (cat) => cat.name
                    );
                    this.setState({ category: cats });
                }
            } else {
                ////// console.log("API error");
            }
        });
        let index = localStorage.getItem("selectedCategoryIndex");
        this.setState({ selectedCategory: index });

        const items = JSON.parse(localStorage.getItem("anyStoreItems"));
        this.setState({ items: items });

        const storeAddress = JSON.parse(
            localStorage.getItem("storeSetAddress")
        );
        this.setState({ storeAddress: storeAddress });

        const deliveryAddress = JSON.parse(
            localStorage.getItem("storeDeliveryAddress")
        );
        this.setState({ deliveryAddress: deliveryAddress });

        if (storeAddress) {
            this.checkStoreAddress(storeAddress, deliveryAddress);
        } else {
            this.setState({ loading: false });
        }
    }

    alertSheet = () => {
        this.setState({ place_order_alert: true });
    };
    alertSheetClose = () => {
        this.setState({ place_order_alert: false });
    };

    handleSchedule = (type) => {
        this.setState({ needItems: false });
        // ////// console.log('items', this.state.items?.length)
        if (this.state.items === null || this.state.items.length == 0) {
            ////// console.log('items')
            const data = this.state.items || [];
            data.push({ item: "", quantity: 1 });
            this.setState({ items: data });
        } else if (
            this.state.items[this.state.items.length - 1].item &&
            this.state.items.length < 10
        ) {
            const data = this.state.items || [];
            data.push({ item: "", quantity: 1 });
            this.setState({ items: data });
        } else {
            ////// console.log("Max limit Reached");
            this.setState({ noText: true });
        }
    };

    selectedItem(item, index) {
        let data = this.props.selectedItem.products;
        if (data.length > 0) {
            let indexOfSelectedItem = data.findIndex(
                (value, index) => value.id == item.id
            );
            if (indexOfSelectedItem >= 0) {
                data.splice(indexOfSelectedItem, 1);
            } else {
                data.push(item);
            }
        } else {
            data.push(item);
        }
    }

    locationStore = (e) => {
        e.preventDefault();
        localStorage.setItem("LocationFrom", "STORE");
        localStorage.setItem("BackTo", "STORE");
        this.props.history.push("/set-location");
    };
    locationUser = (e) => {
        if (this.state.storeAddress) {
            this.setState({ noStoreAddress: false });
            e.preventDefault();
            localStorage.setItem("LocationFrom", "STORE");
            localStorage.setItem("BackTo", "DELIVERY");
            this.props.history.push("/set-location");
        } else {
            this.setState({ noStoreAddress: true });
        }
    };

    openCategoryMenu = (index) => {
        localStorage.setItem("selectedCategoryIndex", index);
        this.setState({ selectedCategory: index });
    };

    checkStoreAddress = (storeAddress, deliveryAddress) => {
        this.props.checkStoreAddress(storeAddress).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, message_store: null });
                if (storeAddress && deliveryAddress) {
                    this.checkDeliveryAddress(
                        deliveryAddress,
                        response.payload.city
                    );
                } else {
                    this.setState({ loading: false });
                }
            } else {
                this.setState({
                    loading: false,
                    message_store: response.payload.message,
                });
            }
        });
    };

    checkDeliveryAddress = (deliveryAddress, city_id) => {
        if (city_id) {
            this.props
                .checkDeliveryAddress(deliveryAddress, city_id)
                .then((response) => {
                    // ////// console.log(response.payload)
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        this.setState({
                            loading: false,
                            city_id: city_id,
                            delivery_charge: response.payload.delivery_charge,
                            any_store_fee: response.payload.any_store_fee,
                            message_delivery: null,
                        });
                    } else {
                        this.setState({
                            loading: false,
                            message_delivery: response.payload.message,
                        });
                    }
                });
        } else {
            this.setState({ loading: false });
        }
    };

    sendOrderCompleteResponse = (id, payment_id, order_id, signature) => {
        axios
            .post(ANYSTORE_PROCESS_STATUS_UPDATE_RAZORPAY_URL, {
                id: id,
                token: this.props.user.data.auth_token,
                payment_id,
                order_id,
                signature,
            })
            .then((response) => {
                if (response?.data?.success) {
                    localStorage.removeItem("storeDeliveryAddress");
                    localStorage.removeItem("storeSetAddress");
                    localStorage.removeItem("anyStoreItems");
                    localStorage.removeItem("selectedCategoryIndex");
                    this.setState({
                        loading: false,
                        city_id: null,
                        storeAddress: null,
                        deliveryAddress: null,
                        categorySelected: null,
                        items: [],
                    });
                }

                setTimeout(() => {
                    this.props.history.push(
                        "/pickup-success/" + response?.data?.data?.id
                    );
                    this.setState({ loading: false });
                }, 300);
            });
    };

    successRazor = () => {
        this.setState({ order_succes: true, order_confirm: false });

        // setTimeout(() => {
        // 	this.context.router.history.push("/my-orders");
        // }, 5000);
    };
    launchRazor = (order, history, runFunction, successRazor) => {
        axios
            .post(RAZORPAY_ANYSTORE_URL, {
                amount: parseFloat(order?.total).toFixed(2),
                id: order?.id,
                token: this.props.user.data.auth_token,
            })
            .then((res) => {
                // //// console.log(res.data.response.id);
                if (res.data.razorpay_success) {
                    const options = {
                        key: "rzp_live_KvJRO6e2wr8Yb9",
                        amount: parseFloat(order?.total).toFixed(2) * 100,
                        webview_intent: true,
                        name: "Grosav",
                        currency: "INR",
                        order_id: res.data.response.id,
                        payment_capture: 1,
                        method: {
                            netbanking: true,
                            card: true,
                            wallet: false,
                            upi: true,
                        },

                        handler(response) {
                            //// console.log("Final Response", response);
                            runFunction(
                                order?.id,
                                response.razorpay_payment_id,
                                response.razorpay_order_id,
                                response.razorpay_signature
                            );

                            successRazor();
                        },
                        modal: {
                            ondismiss: function () {
                                //// console.log("closed");
                                runFunction(order.id, null, null, null);
                            },
                            confirm_close: true,
                        },
                        prefill: {
                            name: this.props.user.data.name,
                            contact: this.props.user.data.phone,
                            email: this.props.user.data.email
                                ? this.props.user.data.email
                                : "",
                        },
                    };
                    const rzp1 = new window.Razorpay(options);
                    rzp1.open();
                }
            });
    };
      launchPaytm = (order) => {
            console.log(`${PAYTM_PAYMENT_ANYSTORE_URL}/${order.id}`,"FFF");
    
            window.location = `${PAYTM_PAYMENT_ANYSTORE_URL}/${order.id}`;
        };
    

    placeStoreOrder = () => {
        const { user } = this.props;
        const { category } = this.state;
        const {
            items,
            selectedCategory,
            storeAddress,
            deliveryAddress,
            city_id,
            payment_gateway_selected

        } = this.state;
        const categorySelected = category[selectedCategory]
            ? category[selectedCategory]
            : null;
        if (items && items.length > 0) {
            this.setState({ loading: true });

            this.props
                .placeAnyStoreOrder(
                    user.data.auth_token,
                    city_id,
                    storeAddress,
                    deliveryAddress,
                    categorySelected,
                    items,
                    payment_gateway_selected
                )
                .then((response) => {
                    if (
                        response &&
                        response.payload &&
                        response.payload.success
                    ) {
                        console.log(  
                        response.payload.pickup.payment_gateway_selected ,'payment_gateway_selected'

                        )
                        if (response.payload.pickup.payment_mode === "ONLINE") {
                            console.log("ASDASDADS")
                            switch (response.payload.pickup.payment_gateway_selected ) {
                                case "Razorpay":
                                    console.log('NOOO')
                                    this.launchRazor(
                                        response.payload.pickup,
                                        this.props.history,
                                        this.sendOrderCompleteResponse,
                                        this.successRazor
                                    );
                                    break;

                                case "Paytm":
                                    console.log('NOOOEEE')
                                
                                    this.launchPaytm(
                                        response.payload.pickup,
                                        this.props.history,
                                    );
                                    break;

                                default:
                                    console.warn(
                                        "Unknown payment gateway selected:",
                                        response.data.payment_gateway_selected
                                    );
                            }

                            // window.location =
                            //     ANY_STORE_PAYTM_PAYMENT_URL + "/" + response.payload.pickup.id;
                        } else {
                            this.props.history.push(
                                "/pickup-success/" + response.payload.pickup.id
                            );

                            localStorage.removeItem("storeDeliveryAddress");
                            localStorage.removeItem("storeSetAddress");
                            localStorage.removeItem("anyStoreItems");
                            localStorage.removeItem("selectedCategoryIndex");
                            this.setState({
                                loading: false,
                                city_id: null,
                                storeAddress: null,
                                deliveryAddress: null,
                                categorySelected: null,
                                items: [],
                            });
                        }
                    } else {
                        ////// console.log("API ERROR", response);
                    }
                });
        } else {
            this.setState({ needItems: true });
        }
    };

    getAnystore = (delivery_charge, any_store_fee) => {
        let data = false;
        if (delivery_charge && any_store_fee) {
            data = true;
            return data;
        } else {
            data = false;
            return data;
        }
    };
    render() {
        const {
            message_delivery,
            message_store,
            storeAddress,
            deliveryAddress,
            delivery_charge,
            any_store_fee,
            anyStoreImage,
            address,
            category,
        } = this.state;
        const { selectedItem, product, stores, user } = this.props;
        {
            console.log("stores", stores);
        }
        
        
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="10"
                                y="40"
                                rx="8"
                                ry="8"
                                width="150"
                                height="12"
                            />
                            <rect
                                x="10"
                                y="68"
                                rx="8"
                                ry="8"
                                width="121"
                                height="12"
                            />
                            <rect
                                x="228"
                                y="48"
                                rx="10"
                                ry="10"
                                width="35"
                                height="32"
                            />
                            <rect
                                x="10"
                                y="100"
                                rx="15"
                                ry="15"
                                width="258"
                                height="115"
                            />
                            <rect
                                x="10"
                                y="230"
                                rx="8"
                                ry="8"
                                width="121"
                                height="12"
                            />
                            <rect
                                x="10"
                                y="255"
                                rx="8"
                                ry="8"
                                width="258"
                                height="25"
                            />
                            <rect
                                x="10"
                                y="297"
                                rx="6"
                                ry="6"
                                width="225"
                                height="12"
                            />
                            <rect
                                x="16"
                                y="325"
                                rx="15"
                                ry="15"
                                width="248"
                                height="95"
                            />
                            <rect
                                x="10"
                                y="434"
                                rx="6"
                                ry="6"
                                width="180"
                                height="12"
                            />
                            <rect
                                x="10"
                                y="460"
                                rx="8"
                                ry="8"
                                width="258"
                                height="28"
                            />
                            <rect
                                x="10"
                                y="505"
                                rx="6"
                                ry="6"
                                width="100"
                                height="12"
                            />
                            <rect
                                x="0"
                                y="530"
                                rx="8"
                                ry="8"
                                width="300"
                                height="45"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {stores &&
                        stores?.stores &&
                        stores?.stores.length > 0 ? (
                            <React.Fragment>
                                <div
                                    className="section-div"
                                    style={
                                        this.state.pause_anystore === 1
                                            ? {
                                                  pointerEvents: "none",
                                                  filter: "grayscale(1)",
                                                  overflow: "hidden",
                                                  height: "100vh",
                                              }
                                            : {}
                                    }
                                >
                                    <div className="mobile-image">
                                        <MobileBackButtonWithCenterTitle
                                            route="home"
                                            wayToHome={true}
                                        />
                                        <div className="mobile-anystore-top d-flex flex-row justify-content-between align-items-center mt-1 px-4 pb-3">
                                            <div
                                                style={{
                                                    fontWeight: "700",
                                                    fontSize: "26px",
                                                }}
                                            >
                                                Order anything in
                                                <div>3 simple steps</div>
                                            </div>
                                            <div
                                                className="mobile-product-searchbtn "
                                                style={{ padding: "12px" }}
                                            >
                                                <div className="">
                                                    <Link to="/explore-menu">
                                                        <BsSearch
                                                            size={"1.9em"}
                                                            style={{
                                                                color: "#DA6317",
                                                            }}
                                                        />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                      </div>

                                    {stores?.video?.value ? (
                                        <div className="px-3">
                                            <ReactPlayer
                                                url={stores?.video?.value}
                                                playing={false}
                                                light={true}
                                                controls={true}
                                                width="100%"
                                                height="200px" // Adjust height as needed
                                                style={{
                                                    borderRadius: "10px",
                                                    overflow: "hidden",
                                                }} // Add border radius
                                            />
                                        </div>
                                    ) : (
                                        <div className="frame-img-any-store d-flex  justify-content-between mt-1">
                                            <div className="mt-2 p-2">
                                                <div
                                                    className="anystore-off px-2 "
                                                    style={{
                                                        fontWeight: "800",
                                                        fontSize: "16px",
                                                        color: "#fff",
                                                        width: "61%",
                                                    }}
                                                >
                                                    Buy Anything from any store
                                                    in the city
                                                </div>

                                                <div
                                                    className="anystore-east  px-2 mt-2"
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "#FFF5F1",
                                                    }}
                                                >
                                                    Make a list of items you
                                                    want and
                                                    <div>
                                                        {" "}
                                                        we wil get it for you
                                                    </div>
                                                </div>
                                                <div
                                                    className="px-2 mt-3"
                                                    style={{
                                                        color: "#fff",
                                                        fontSize: "21px",
                                                        fontWeight: "700",
                                                        height: "10px",
                                                    }}
                                                >
                                                    {/* 50% off */}
                                                </div>
                                            </div>
                                            <div className="d-flex position-relative">
                                                <div
                                                    className="position-absolute top-0 start-40 translate-middle-x"
                                                    style={{
                                                        top: "-33px",
                                                        left: "-64px",
                                                        zIndex: "2",
                                                    }}
                                                >
                                                    <img
                                                        src={youngWoman}
                                                        alt="youngwomen"
                                                    />
                                                </div>
                                                <div
                                                    className="store-coupon-image  d-flex position-absolute"
                                                    style={{
                                                        bottom: "90px",
                                                        right: "160px",
                                                        opacity: "0.3",
                                                    }}
                                                >
                                                    <img
                                                        src={Ellipse}
                                                        alt="eli"
                                                    />
                                                </div>
                                                <div
                                                    className="anystore-images  d-flex position-absolute"
                                                    style={{
                                                        top: "15px",
                                                        left: "-50px",
                                                        objectFit: "cover",
                                                        opacity: "0.4",
                                                    }}
                                                >
                                                    <img
                                                        src={Pattern}
                                                        alt="pat"
                                                        className="anystore-pattern"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div className="ms-4  mt-3">
                                        <span
                                            style={{
                                                fontWeight: "700",
                                                fontSize: "17px",
                                                lineHeight: "23px",
                                            }}
                                        >
                                            Choose Category{" "}
                                        </span>
                                    </div>
                                    <div
                                        ClassName="d-flex flex-row justify-content-between align-items-center  mt-4"
                                        style={{
                                            fontWeight: "500",
                                            fontSize: "12px",
                                            lineHeight: "16px",
                                            color: "#B3B3B3",
                                            marginLeft: "-1em",
                                        }}
                                    >
                                        <div
                                            className="mobile-anystore-card d-flex flex-row justify-content-between align-items-center px-2 py-2 mt-2"
                                            onClick={() => this.openItemMenu()}
                                        >
                                            <div className="d-flex justify-content-between align-items-center">
                                                {this.state.selectedCategory ===
                                                null ? (
                                                    <span>
                                                        {" "}
                                                        Documents | Books |
                                                        Stationary | Grocery |
                                                        etc
                                                    </span>
                                                ) : (
                                                    <div>
                                                        {
                                                            category[
                                                                this.state
                                                                    .selectedCategory
                                                            ]
                                                        }
                                                    </div>
                                                )}
                                            </div>
                                            <FaAngleRight />
                                        </div>
                                    </div>
                                    <div
                                        className="px-4 mt-4"
                                        style={{
                                            fontWeight: "700",
                                            fontSize: "17px",
                                            lineHeight: "23px",
                                            color: "#09051C",
                                        }}
                                    >
                                        Make a list of items(max 10 allowed)
                                    </div>
                                    <div className="px-4  mb-3 text-danger">
                                        {/* Maximum budget of ₹ 1000 and maximum weight of 5 kg. */}
                                        Order between ₹ 1 - ₹ 1000 and Weigth
                                        0.1gm to 5kg
                                    </div>
                                    {this.state.needItems ? (
                                        <Shake duration={250} right>
                                            <div
                                                className="text-center mb-3"
                                                style={{ color: "red" }}
                                            >
                                                You Need To Add an item to Place
                                                Order
                                            </div>
                                        </Shake>
                                    ) : (
                                        ""
                                    )}
                                    <div
                                        className="mobile-anystore-card-list justify-content-between align-items-center mx-4 px-2 pt-2"
                                        style={{ backgroundColor: "#FAFAFA" }}
                                    >
                                        {this.state.items &&
                                            this.state.items.length > 0 &&
                                            this.state.items.map(
                                                (item, index) => (
                                                    <div className=" d-flex flex-row justify-content-between align-items-center mb-2">
                                                        <div
                                                            className="d-flex flex-row align-items-center mt-2"
                                                            style={{
                                                                fontWeight:
                                                                    "500",
                                                                fontSize:
                                                                    "12px",
                                                                lineHeight:
                                                                    "16px",
                                                                color: "#B3B3B3",
                                                            }}
                                                        >
                                                            <BsDot
                                                                style={{
                                                                    color: "E86D28",
                                                                    fontSize:
                                                                        "1.5rem",
                                                                }}
                                                            />

                                                            {/* {item:'',quantiy:0} */}
                                                            {/*  */}
                                                            <input
                                                                type="text"
                                                                placeholder="Add item "
                                                                value={
                                                                    item.item
                                                                }
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    this.changeItem(
                                                                        e,
                                                                        index
                                                                    );
                                                                    this.setState(
                                                                        {
                                                                            noText: false,
                                                                        }
                                                                    );
                                                                }}
                                                                className="py-2 "
                                                                style={{
                                                                    border: "none",
                                                                    outline:
                                                                        "none",
                                                                    color: "black",
                                                                    fontSize:
                                                                        "16px",
                                                                    backgroundColor:
                                                                        "transparent",
                                                                    caretColor:
                                                                        "#FF9012", // borderBottom:'1px solid gray'
                                                                }}
                                                            />
                                                        </div>
                                                        <div
                                                            className="d-flex justify-content-between align-items-center px-3 py-2 bg-white"
                                                            style={{
                                                                border: "1px solid orange",
                                                                borderRadius:
                                                                    "12px",
                                                            }}
                                                        >
                                                            {this.state
                                                                .noText ? (
                                                                <TiMinus
                                                                    style={{
                                                                        fontSize:
                                                                            "10px",
                                                                        color: "#E86D28",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <TiMinus
                                                                    onClick={() =>
                                                                        this.handleDecrement(
                                                                            index
                                                                        )
                                                                    }
                                                                    style={{
                                                                        fontSize:
                                                                            "10px",
                                                                        color: "#E86D28",
                                                                    }}
                                                                />
                                                            )}
                                                            <div
                                                                className="ms-2 me-2 "
                                                                style={{
                                                                    color: "#E86D28",
                                                                }}
                                                            >
                                                                {item.quantity}
                                                            </div>
                                                            {this.state
                                                                .noText ? (
                                                                <TiPlus
                                                                    style={{
                                                                        fontSize:
                                                                            "10px",
                                                                        color: "#E86D28",
                                                                    }}
                                                                />
                                                            ) : (
                                                                <TiPlus
                                                                    onClick={
                                                                        this
                                                                            .state
                                                                            .noText
                                                                            ? ""
                                                                            : () =>
                                                                                  this.handleIncrement(
                                                                                      index
                                                                                  )
                                                                    }
                                                                    style={{
                                                                        fontSize:
                                                                            "10px",
                                                                        color: "#E86D28",
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            )}
                                        {this.state.noText ? (
                                            <Shake duration={250} right>
                                                <div
                                                    className="text-center mb-2"
                                                    style={{ color: "red" }}
                                                >
                                                    {" "}
                                                    Enter Item Name to Add more
                                                    Items
                                                </div>
                                            </Shake>
                                        ) : (
                                            ""
                                        )}
                                        <div
                                            className="text-center py-1"
                                            onClick={() => {
                                                this.handleSchedule("items");
                                            }}
                                        >
                                            <span
                                                className="text-center py-1 px-3"
                                                style={{
                                                    color: "#FF9012",
                                                    fontWeight: "500",
                                                    fontSize: "12px",
                                                    lineHeight: "16px",
                                                    textAlign: "center",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "8px",
                                                    border: "1px solid #FF9012",
                                                }}
                                            >
                                                {this.state.items?.length == 0
                                                    ? "Add items"
                                                    : " Add more items"}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="ms-4  mt-3 mb-2"
                                        style={{
                                            fontWeight: "700",
                                            fontSize: "17px",
                                            lineHeight: "23px",
                                        }}
                                    >
                                        Store/Pickup Address
                                    </div>
                                    {this.state.noStoreAddress ? (
                                        <Shake duration={250} right>
                                            <div
                                                className="text-center  mb-2"
                                                style={{ color: "red" }}
                                            >
                                                Add Store/PickUp Address
                                            </div>
                                        </Shake>
                                    ) : (
                                        ""
                                    )}
                                    {message_store ? (
                                        <Shake duration={250} right>
                                            <div
                                                className="text-center  mb-2"
                                                style={{ color: "red" }}
                                            >
                                                {message_store}
                                            </div>
                                        </Shake>
                                    ) : (
                                        ""
                                    )}
                                    <div className="px-3">
                                        <div
                                            className="d-flex flex-row align-items-center  mobile-anystore-searchBar  px-3"
                                            onClick={(e) =>
                                                this.locationStore(e)
                                            }
                                        >
                                            <span className="">
                                                <HiOutlineLocationMarker
                                                    set="curved"
                                                    style={{
                                                        fontSize: "1.5em",
                                                        color: "#FF9012",
                                                    }}
                                                />
                                            </span>
                                            <span className="mobile-home-search w-100 px-2 text-muted">
                                                {storeAddress ? (
                                                    storeAddress.house !== "" &&
                                                    storeAddress.house !==
                                                        storeAddress.address ? (
                                                        <span>
                                                            {" "}
                                                            <span
                                                                style={{
                                                                    fontWeight:
                                                                        "700",
                                                                }}
                                                            >
                                                                {
                                                                    storeAddress.house
                                                                }
                                                            </span>
                                                            {", "}
                                                            {
                                                                storeAddress.address
                                                            }
                                                        </span>
                                                    ) : (
                                                        storeAddress.address
                                                    )
                                                ) : (
                                                    "Select Store/Place/Pickup?"
                                                )}
                                            </span>
                                            <span className="">
                                                <TbEdit size={"1.4em"} />
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="px-4 mt-3 mb-2"
                                        style={{
                                            fontWeight: "700",
                                            fontSize: "17px",
                                            lineHeight: "23px",
                                        }}
                                    >
                                        Delivery Address
                                    </div>
                                    {message_delivery ? (
                                        <Shake duration={250} right>
                                            <div
                                                className="text-center mb-2"
                                                style={{ color: "red" }}
                                            >
                                                {message_delivery}
                                            </div>
                                        </Shake>
                                    ) : (
                                        ""
                                    )}
                                    <div
                                        className=" px-3"
                                        style={
                                            delivery_charge && any_store_fee
                                                ? {
                                                      paddingTop: "8px",
                                                      paddingBottom: "2vw",
                                                  }
                                                : {
                                                      paddingTop: "8px",
                                                      paddingBottom: "35vw",
                                                  }
                                        }
                                        onClick={(e) => this.locationUser(e)}
                                    >
                                        <div className="d-flex flex-row align-items-center  mobile-anystore-searchBar px-3">
                                            <span>
                                                <HiOutlineLocationMarker
                                                    set="curved"
                                                    style={{
                                                        fontSize: "1.5em",
                                                        color: "#FF9012",
                                                    }}
                                                />
                                            </span>
                                            <span className="mobile-home-search w-100 px-2 text-muted">
                                                {deliveryAddress
                                                    ? deliveryAddress.house !==
                                                          "" &&
                                                      deliveryAddress.house !==
                                                          deliveryAddress.address
                                                        ? deliveryAddress.house +
                                                          "," +
                                                          deliveryAddress.address
                                                        : deliveryAddress.address
                                                    : "Select Home/Office/Drop?"}
                                            </span>
                                            <span className="">
                                                <TbEdit size={"1.4em"} />
                                            </span>
                                        </div>
                                    </div>

                                    {this.state.pause_anystore === 1 && (
                                        <div
                                            style={{
                                                textAlign: "center",
                                                background: "black",
                                                color: "white",
                                                position: "fixed",
                                                width: "100%",
                                                bottom: "140px",
                                                padding: "10px 0px",
                                            }}
                                        >
                                            <span>
                                                Service is off duty heavy rush
                                                it will resume soon
                                            </span>
                                        </div>
                                    )}
                                    <Flip
                                        bottom
                                        cascade
                                        collapse
                                        when={this.getAnystore(
                                            delivery_charge,
                                            any_store_fee
                                        )}
                                    >
                                        <div
                                            className=""
                                            style={
                                                delivery_charge && any_store_fee
                                                    ? { paddingBottom: "30vw" }
                                                    : { display: "none" }
                                            }
                                        >
                                            <div className="mobile-Anystore-colorcard  px-3 py-3">
                                                <div className="d-flex flex justify-content-between py-3">
                                                    <div
                                                        style={{
                                                            fontSize: "18px",
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        Delivery Charge
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: "18px",
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        Rs {delivery_charge}
                                                    </div>
                                                </div>
                                                <div className="d-flex flex justify-content-between pb-3">
                                                    <div
                                                        style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        Any Store Convenience
                                                        Fee
                                                    </div>
                                                    <div
                                                        style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        Rs {any_store_fee}
                                                    </div>
                                                </div>
                                                {user && user.success > 0 ? (
                                                    <div
                                                        className="btn-any-store p-3 d-flex justify-content-center "
                                                        onClick={() =>
                                                            this.alertSheet()
                                                        }
                                                    >
                                                        <span
                                                            style={{
                                                                color: "#FEA24B",
                                                                fontSize:
                                                                    "14px",
                                                                fontWeight:
                                                                    "700",
                                                                border: "none",
                                                                outline: "none",
                                                            }}
                                                        >
                                                            Place My Order
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <Link to="/signUp">
                                                        <div className="btn-any-store p-3 d-flex justify-content-center ">
                                                            <span
                                                                style={{
                                                                    color: "#FEA24B",
                                                                    fontSize:
                                                                        "14px",
                                                                    fontWeight:
                                                                        "700",
                                                                    border: "none",
                                                                    outline:
                                                                        "none",
                                                                }}
                                                            >
                                                                Place My Order
                                                            </span>
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>
                                        </div>
                                    </Flip>
                                    <BottomSheet
                                        open={this.state.itemmenu}
                                        onDismiss={() => this._closeItemMenu()}
                                    >
                                        <div className="text-center mt-2 ">
                                            <span
                                                style={{
                                                    fontSize: "19px",
                                                    color: "#FF9012",
                                                }}
                                            >
                                                <b>Select a Category</b>
                                            </span>
                                        </div>

                                        <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                            <div className="d-grid gap-2 px-2 mb-2">
                                                {category?.length > 0 &&
                                                    category?.map(
                                                        (item, index) => (
                                                            <button
                                                                className="offer-sheet-btn "
                                                                style={
                                                                    this.state
                                                                        .selectedCategory ==
                                                                    index
                                                                        ? {
                                                                              background:
                                                                                  "#FF9012",
                                                                              color: "#fff",
                                                                          }
                                                                        : {}
                                                                }
                                                                onClick={() =>
                                                                    this.openCategoryMenu(
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                {item}
                                                            </button>
                                                        )
                                                    )}
                                            </div>
                                        </div>

                                        <div
                                            className="d-flex flex-row align-items-center justify-content-between gap-2 py-2 px-3"
                                            style={{
                                                backgrounColor: "red",
                                                border: "1px 0px 0px 0px solid #aaa",
                                            }}
                                        >
                                            <button
                                                onClick={() => {
                                                    this._closeItemMenu();
                                                    localStorage.removeItem(
                                                        "selectedCategoryIndex"
                                                    );
                                                    this.setState({
                                                        selectedCategory: null,
                                                    });
                                                }}
                                                className="anystr-cancel-button  py-2 w-100"
                                            >
                                                <b style={{ color: "#FF9012" }}>
                                                    Cancel
                                                </b>
                                            </button>
                                            <button
                                                onClick={() =>
                                                    this._closeItemMenu()
                                                }
                                                className="anystr-done-button py-2 w-100"
                                            >
                                                <b style={{ color: "white" }}>
                                                    Done
                                                </b>
                                            </button>
                                        </div>
                                        {/* )} */}
                                    </BottomSheet>
                                    <BottomSheet
                                        open={this.state.place_order_alert}
                                        onDismiss={() => this.alertSheetClose()}
                                        snapPoints={({ maxHeight }) =>
                                            0.5 * maxHeight
                                        }
                                    >
                                        <div
                                            className="p-3"
                                            style={{ background: "none" }}
                                        >
                                            <div className="px-3 pt-3">
                                                <div className="d-flex flex justify-content-between">
                                                    <div
                                                        style={{
                                                            fontSize: "17px",
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        Alert
                                                    </div>
                                                </div>
                                                <div className="d-flex flex justify-content-between pt-3 pb-2">
                                                    <div
                                                        style={{
                                                            fontSize: "16px",
                                                            fontWeight: "500",
                                                        }}
                                                    >
                                                        Please note that orders
                                                        cannot be cancelled.
                                                        However, the convenience
                                                        fee is refundable. Would
                                                        you like to proceed with
                                                        the payment? Click
                                                        'Confirm' to proceed.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="position-fixed px-3 w-100"
                                            style={{
                                                backgrounColor: "red",
                                                border: "1px 0px 0px 0px solid #aaa",
                                                bottom: "10px",
                                            }}
                                        >
                                            <button
                                                type="button"
                                                className="pickup-button btn btn py-2 w-100"
                                                onClick={() =>
                                                    { this.setState({ payment_sheet: true ,place_order_alert: false });}
                                                }
                                            >
                                                <b style={{ color: "white" }}>
                                                    Confirm
                                                </b>
                                            </button>
                                        </div>
                                    </BottomSheet>
                                      <BottomSheet
                                                                open={this.state.payment_sheet}
                                                                onDismiss={() => this.closePayment()}
                                                            >
                                                                <div className="d-flex flex-column justify-content-center px-3 pb-3">
                                                                    <div className="pt-3">
                                                                        <p
                                                                            style={{
                                                                                color: "#7b7b7b",
                                                                                fontSize: "1.2em",
                                                                                fontWeight: "650",
                                                                            }}
                                                                        >
                                                                            Choose Payment Method
                                                                        </p>
                                                                    </div>
                                                                  
                                    
                                                                    {this.state.gateway_name &&
                                                                    this.state.gateway_name.length > 0 ? (
                                                                        this.state.gateway_name
                                                                            .filter(
                                                                                (gateway) => gateway.is_active === 1
                                                                            ) // Only show active gateways
                                                                            .map((gateway, index) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="mobile-card2 py-3 w-100 mt-3 d-flex flex-row justify-content-center align-items-center"
                                                                                    onClick={() => this.handlePaymentSelection(gateway.name)}
                                                                                    style={{
                                                                                        border: "1px solid #fea24b",
                                                                                        cursor: "pointer",
                                                                                    }}
                                                                                >
                                                                                    <img
                                                                                        src={gateway.image}
                                                                                        className="cod"
                                                                                        alt={gateway.name}
                                                                                        style={{
                                                                                            height: "20px",
                                                                                            width: "20px",
                                                                                        }}
                                                                                    />
                                                                                    <span
                                                                                        style={{
                                                                                            fontWeight: "800",
                                                                                            marginLeft: "10px",
                                                                                        }}
                                                                                    >
                                                                                        {gateway.name}
                                                                                    </span>
                                                                                </div>
                                                                            ))
                                                                    ) : (
                                                                        <p className="text-center">
                                                                            No payment gateways available
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </BottomSheet>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="mobile-home-bg-img py-3 d-flex flex-column px-3 justify-content-between ">
                                    <div className="d-flex flex-row justify-content-between w-100">
                                        <div className="d-flex flex-row align-items-center">
                                            <MdStore
                                                style={{ fontSize: "2em" }}
                                            />
                                            <span className="mobile-span-home ps-2">
                                                Any Store
                                            </span>
                                        </div>
                                    </div>
                                    <div className="w-75 mobile-home-span-2 mt-2">
                                        <Link
                                            to={"/set-location"}
                                            style={{ color: "#000" }}
                                        >
                                            <div>
                                                <Truncate lines={1}>
                                                    {address?.house
                                                        ? address?.house + ","
                                                        : ""}
                                                    {" " + address?.address}
                                                </Truncate>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                                <div
                                    className="d-flex flex-column align-items-center"
                                    style={{ paddingTop: "20vw" }}
                                >
                                    <div className="pt-5 pt-3 d-flex flex-column align-items-center">
                                        <img
                                            src={map}
                                            alt="no items"
                                            style={{
                                                objectFit: "cover",
                                                width: "70%",
                                            }}
                                        />
                                    </div>
                                    <span className="pt-4 fw-bolder fs-6">
                                        Sorry!
                                    </span>
                                    <span className="mt-1 fw-bolder fs-6">
                                        We don't deliver to your location.
                                    </span>
                                </div>
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                {this.state.itemmenu ? "" : <Footer active_any_store={true} />}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    selectedItem: state.anyStore,
    user: state.user.user,
    stores: state.mobileitems.category,
});

export default connect(mapStateToProps, {
    getGateways,
    getAllCategory,
    getAllAnyStoreCourierCategory,
    checkStoreAddress,
    checkDeliveryAddress,
    placeAnyStoreOrder,
})(index);
