import {
    CHECK_DELIVERY_ADDRESS,
    CHECK_STORE_ADDRESS,
    PLACE_ANY_STORE_ORDER,

} from "./actionType";
import {
    CHECK_DELIVERY_ADDRESS_URL,
    CHECK_STORE_ADDRESS_URL,
    PLACE_ANY_STORE_ORDER_URL,
    GET_ALL_STORES_URL
} from "../../../api/index";
import Axios from "axios";


export const checkStoreAddress = (storeAddress) => (dispatch) => {
    return Axios.post(CHECK_STORE_ADDRESS_URL, {
        storeAddress
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: CHECK_STORE_ADDRESS, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const checkDeliveryAddress = (deliveryAddress, city_id) => (dispatch) => {
    return Axios.post(CHECK_DELIVERY_ADDRESS_URL, {
        deliveryAddress, city_id
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: CHECK_DELIVERY_ADDRESS, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const placeAnyStoreOrder = (token, city_id, storeAddress, deliveryAddress, categorySelected,items,payment_gateway_selected, ) => (dispatch) => {
    return Axios.post(PLACE_ANY_STORE_ORDER_URL, {
        token, city_id, storeAddress, deliveryAddress, categorySelected, items,payment_gateway_selected
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: PLACE_ANY_STORE_ORDER, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};


